import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { IoCartOutline } from 'react-icons/io5';
import { useRouter } from 'next/router';
import Button from '@/components/atoms/Button';
import styles from './index.module.scss';
import { GeneralModal } from '@/components/molecules';
import { hideChangeModalInfo, toggleCartModal } from '@/redux/cart/cartReducer';

const ChangeModalCart = () => {
  const router = useRouter();
  const { showChangeModal } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  if (!showChangeModal?.visible) return null;
  const onClose = () => {
    dispatch(hideChangeModalInfo());
  };
  const onGoToCart = () => {
    dispatch(toggleCartModal(true));
    onClose();
  };
  return (
    <GeneralModal
      onClose={onClose}
      closeColor="white"
      modalStyles={{
        backgroundColor: 'var(--color-black)',
        maxWidth: '600px',
        marginInline: '20px'
      }}
    >
      <div className={styles.container}>
        <Image
          src="/brandlogos/vaypol.webp"
          width={180}
          height={25}
        />
        <p>{showChangeModal.message}</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Button
            onClick={onClose}
            variant="primary"
            style={{ maxWidth: '200px' }}
            label="Aceptar"
          />
          {router.pathname.includes('checkout') && (
            <Link
              absolutePath
              href="/"
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                marginTop: '10px',
                color: 'black'
              }}
            >
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                  cursor: 'pointer'
                }}
                onClick={onGoToCart}
              >
                <IoCartOutline
                  color="white"
                  fontSize={25}
                />
                <span
                  style={{
                    color: 'white',
                    marginTop: '5px',
                    fontSize: '14px',
                    textDecoration: 'underline'
                  }}
                >
                  Ir al carrito
                </span>
              </button>
            </Link>
          )}
        </div>
      </div>
    </GeneralModal>
  );
};
export default ChangeModalCart;
